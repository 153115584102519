
import React from 'react';

const APILink = props => {
    let name = props.apiLinkData.name
    let apiLink = props.apiLinkData.url
    let schema = props.schemaDefinition
    
    let tags = [];
    for (const key in schema.paths){
      if('post' in schema.paths[key]){
        tags.push(schema.paths[key].post.tags[0])
      }
      if('get' in schema.paths[key]){
        tags.push(schema.paths[key].get.tags[0])
      }
    }
    
    // unique items
    let subMenuItems = [...new Set(tags)];

    function handleClick() {
      props.updateDefinitionLink(apiLink)
    }

    function goToItem(hash) {
      // lowercase and replace space with underscore
      window.location.hash = "#operations-tag-" + hash.split(' ').join('_');
    }

  return (    
    <div>
      <div className="api-link" onClick={() => handleClick()}>{name}</div>
      <ul className="submenu-items">
      {
        subMenuItems.map(function(name, index){
          return <li className="submenu-item" key={ index } onClick={() => goToItem(name)} >{name}</li>;
        })
      }
      </ul>
    </div>
  )
}

export default APILink;