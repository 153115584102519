import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui';
import Config from '../../config/config.json';
import Sidebar from '../Sidebar/Sidebar.js'

class App extends Component {

  constructor(props) {
      super(props);
      this.state = {
        organizationConfig: Config.siteInfo,
        definitionLink: Config.apiLinks[0].url,      
        definitionList: Config.apiLinks,
        schemaDefinition: {}
      }    
      this.updateDefinitionLink = this.updateDefinitionLink.bind(this)
      this.updateApiDefinition = this.updateApiDefinition.bind(this)
      this.fetchApiDefinition = this.fetchApiDefinition.bind(this)
      
    }

  /**
   * render onload
   */
  componentDidMount() {

    this.fetchApiDefinition(this.state.definitionLink)
      .then( jsonObject => { 
        console.log(jsonObject);
        this.updateApiDefinition( jsonObject )
    }); 
   
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: this.state.definitionLink,
      //docExpansion: true,
    })
  }

  /**
   * render on update
   */
  componentDidUpdate() {  
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: this.state.definitionLink,
      //docExpansion: true, 
    })
  }
  
  updateDefinitionLink(newLink) {
    this.setState({
      definitionLink: newLink
    })
  }

  updateApiDefinition(jsonObject){
    console.log(jsonObject); 
    this.setState({
      schemaDefinition: jsonObject
    })
  }

  /** 
   * fetches the api schema as json 
   */
  fetchApiDefinition(url){
    return fetch(url).then( response => { 
      return response.json()
    })  
  }

  render() {
    return (
      <div className="App">
        <Sidebar 
          organizationConfig={this.state.organizationConfig}
          definitionList={this.state.definitionList}
          updateDefinitionLink={this.updateDefinitionLink}
          getOrganizationData={this.getOrganizationData}
          schemaDefinition={this.state.schemaDefinition}
        />
        <div id="api-data" />
      </div>
    );
  }
}

export default App;
